<template>
  <div class="NotificationsWebhook">
    <div class="top">
      <div class="title">Discord Notification</div>
    </div>
    <div class="card">
      <div class="card-body">
        <discord-notification :monitor="monitor"
                              :general="general"
                              @update="$emit('update')" />
      </div>
    </div>
  </div>
</template>

<script>
import DiscordNotification from '@/components/Monitor/Notifications/DiscordWebhook/Discord.vue'

export default {
  components: {
    DiscordNotification
  },

  metaInfo: {
    title: 'Discord Notifications'
  },

  props: {
    monitor: {
      required: false,
      type: Object
    },
    general: {
      required: false,
      default: false,
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.NotificationsWebhook {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      font-weight: 400;
      line-height: 1.5;
      color: #444;
    }
  }
}
</style>
